<template>
<body class="home-page page basicpage light-theme">

    <div id="oc-wrapper" class="oc-container-v2">
        <header class="header-v2 header-v2-cn" id="header-v2" style="display: block;">

            <Head></Head>
        </header>
        <section id="oc-container" class="oc-container-v2">

            <div>

            </div>

            <div class="root responsivegrid">

                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                    <div class="responsivegrid aem-GridColumn aem-GridColumn--default--12">

                        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                            <div class="newproductlistbanner parbase aem-GridColumn aem-GridColumn--default--12">

                                <main class="product-main_v2-3 product-main_v2-3-cn">
                                    <section class="product-recommond">
                                        <div class="product-type">

                                            <div class="product-header tab-warp-dom" id="product-header">
                                                <div class="border-box">
                                                    <div class="header-box">
                                                        <p class="header-box-title" style="padding-top:0px">
                                                            <span>解决方案</span>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div class="nav-header-box header-box">
                                                    <!-- <ul class="nav-header-list">

                                                        <li class="active" data-type="all_item1">
                                                        </li>
                                                        <li data-type="all_item2"></li>
                                                    </ul> -->

                                                </div>
                                            </div>
                                            <div class="swiper-container type-box">
                                                <div class="swiper-wrapper type-list">
                                                    <div class="swiper-slide active">
                                                        <div class="type-list_item" v-for="(d,i) in list" :key="i">
                                                            <router-link :to="'/solution-info/'+d.id" class="item_link" style="text-decoration: none;">
                                                                <picture>
                                                                    <source :srcset="baseUrl + d.imgPathM" media="(max-width: 767px)" type="image/webp">
                                                                        <source :srcset="baseUrl + d.imgPathM" media="(max-width: 767px)">
                                                                        <source :srcset="baseUrl + d.imgPath" type="image/webp">
                                                                    <img :src="baseUrl + d.imgPath" alt="三合一卡片解决方案" src-webp="true" />
                                                                </picture>
                                                            </router-link>
                                                            <div class="description" style="color:#ffffff">
                                                                <!-- <a href="solution.html">
                                                                    <div class="title">{{ d.name }}</div>
                                                                </a> -->
                                                                <div class="title">{{ d.name }}</div>
                                                                <div class="subtitle">{{d.remark}}</div>
                                                                <div class="price">

                                                                    <span class="buy-btn btn" style="background-color:; color:;" @click="toLink(d.id)">了解更多

                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </main>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>

        <span>

            <section class="light-theme">
                <footer id="footer">
                    <Foot></Foot>
                </footer>
            </section>
        </span>

    </div>

</body>
</template>

<script>
import {
    solutionData,
    solutionInfo
} from '@/request/api'
import Head from '../../components/heads.vue'
import Foot from '../../components/footer.vue'
export default {
    components: {
        Head,
        Foot
    },
    data() {
        return {
            list: [],
            baseUrl: "https://www.inrunonline.com/api/sys/common/static/"
        }
    },
    created() {
            solutionData().then((res) => {
                this.list = res.result;
            })
    },
    methods :{
        toLink(id){
            solutionInfo({id : id}).then((res) => {
                let data = res.result;
                if(data && data.content){
                    this.$router.push({
                        name:'solutionInfo',
                        params:{
                            id:id
                        }
                    })
                   
                }
            })
        }
    }
}
</script>

<style>
body.app-mode .cp-site-language-tip {
    display: none !important;
}

body.app-mode .cp-notice-bar {
    display: none !important;
}

body.app-mode #oc-header {
    display: none !important;
}

body.app-mode .new-header {
    display: none !important;
}

body.app-mode #header-v2 {
    display: none !important;
}

body.app-mode #footer {
    display: none !important;
}

body.app-mode #oc-container {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

body.app-mode #oc-wrapper {
    padding-top: 0 !important;
}
</style>
<style>
    .oh-nav-pcseek {
        position: relative;
        z-index: 999;
    }

    /* 导航购物车图标 */
    @media(max-width: 767.98px) {
        body #footer .contact .contact-tab .tab-card .tab-card-wrap {
            padding-left: 0;
        }
    }
</style>
<style>
#oc-header .oh-nav-submenus-wrapper .pc-nav-series-products .oh-nav-series-product-list .swiper-slide .pTitle {
    max-width: 200px;
}

.header-v2 .header-main-pc .header-right .search::before,
.header-v2 .header-main-mo .header-menu .search::before {
    content: '';
    display: none;
}
</style>

<style>
    #footer nav dl {
        width: 200px
    }

    @media (max-width: 767.98px) {
        #footer nav dl {
            width: 100%
        }
    }

    @media (max-width: 1024px) {
        body.clean-theme #oc-container {
            margin-top: -50px;
        }

        .header-v2 .header-main-mo .header-menu.search-show {
            overflow-x: unset;
        }

        .header-v2 .header-main-mo .header-menu.search-hide {
            overflow-x: hidden;
        }
    }
</style>
<style>
    @media screen and (max-width: 768px) {

        body.support-home-page.show-cookie-tip .sow_black,
        body.support-page-stand.show-cookie-tip .sow_black,
        body.support-page.show-cookie-tip .sow_black {
            bottom: .64rem !important;
        }

        .support-home-page .sow_black,
        .support-page-stand .sow_black,
        .support-page .sow_black {
            bottom: 12px !important;
        }

        .oh-nav-mbseek .user-info .user-pic img {
            width: 100%;
        }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1919px) {

        body.support-home-page.show-cookie-tip .sow_customerIcon,
        body.support-page-stand.show-cookie-tip .sow_customerIcon,
        body.support-page.show-cookie-tip .sow_customerIcon {
            transition: bottom .3s;
            bottom: 125px !important;
        }

        body.support-home-page .sow_customerIcon,
        body.support-page-stand .sow_customerIcon,
        body.support-page .sow_customerIcon {
            transition: bottom .3s;
            bottom: 68px !important;
        }
    }

    @media only screen and (min-width: 1920px) and (max-width: 999999px) {

        body.support-home-page.show-cookie-tip .sow_customerIcon,
        body.support-page-stand.show-cookie-tip .sow_customerIcon,
        body.support-page.show-cookie-tip .sow_customerIcon {
            transition: bottom .3s;
            bottom: 142px !important;
        }

        body.support-home-page .sow_customerIcon,
        body.support-page-stand .sow_customerIcon,
        body.support-page .sow_customerIcon {
            transition: bottom .3s;
            bottom: 85px !important;
        }
    }
</style>
